.dashboard_header_icon {
    font-family: Roboto !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.19 !important;
    letter-spacing: normal !important;
    text-align: left !important;
    color: #1f396d !important;
  }
  .dashboard_header {
    font-family: Roboto !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.19 !important;
    letter-spacing: normal !important;
    text-align: left !important;
    color: #1f396d !important;
    padding-left: 1em !important;
    padding-top: 1em !important;
  }
  .dashboard_noticepanel_text {
      font-family: Roboto !important;
      font-size: 14px !important;
      font-weight: normal !important;
      font-stretch: normal !important;
      font-style: normal !important;
      line-height: 1.29 !important;
      letter-spacing: normal !important;
      color: #4d5f75 !important;
  }
  .paper {
    height: 100% !important;
    width: 100% !important;
    flex-grow: 1 !important;
    font-family: Roboto !important;
    border: none !important;
  }
  .filter_container {
    background:white !important;
    position: sticky !important;
    top: 0 !important;
    padding: 15 !important;
    z-index: 1101 !important;
    overflow-y: scroll !important;
    box-shadow:  0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
  }
  .filter_container::-webkit-scrollbar {
    display: none !important;
  }
  .boldText {
    font-family: Roboto !important;
    font-size: 22px !important;
    font-weight: 500 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.14 !important;
    letter-spacing: normal !important;
    text-align: left !important;
    color: #4d5f75 !important;
  }
  .firstPipe {
    width: 3px !important;
    height: 16px !important;
    border-radius: 2px !important;
    background-color: #2892d7 !important;
  }
  .secondPipe {
    width: 3px !important;
    height: 9px !important;
    border-radius: 2px !important;
    background-color: #2ca58d !important;
  }
  .thirdPipe {
    width: 3px !important;
    height: 9px !important;
    border-radius: 2px !important;
    background-color: #077187 !important;
  }


