.send_button {
        width: 120px;
        height: 48px;
        background: #2892d7 !important;
        color: white !important;
        border-radius: 28px !important;
}
.profile_send_button {
        width: 64px;
        height: 32px;
        background: #2892d7 !important;
        color: white !important;
        border-radius: 28px !important;
}
.license_set_button {
  width: 130px;
  height: 32px;
  background: #2892d7 !important;
  color: white !important;
  border-radius: 28px !important;
}
.login_text_box {
        width: 400px; 
        height: 48px; 
        border-radius: 4px !important;
        background-color: #ffffff !important;
        margin-top: 2em !important;
        margin-bottom: 1em !important;
}
.profile_text_box {
      width: 360px;
      height: 40px;
      border-radius: 4px !important;
}
.overall_box {
    width: 1920px;
    height: 936px;
}
.login_box {
    width: 592px;
    height: 936px;
    box-shadow: 0 8px 12px 0 rgba(31, 57, 109, 0.05);
    background: #ffffff;
}
.readiness_text {
    width: 429px;
    height: 115px;
    font-family: 'Montserrat', sans-serif !important;
    font-size: 68px !important;
    font-weight: 900 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.06 !important;
    letter-spacing: 1.7px !important;
    text-align: left !important;
    color: #1f396d !important;
}
.hundredpercent_text {
    width: 301px;
    height: 50px;
    font-family: 'Montserrat', sans-serif !important;
    font-size: 102px !important;
    font-weight: 900 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 0.16 !important;
    letter-spacing: 2.55px !important;
    text-align: left !important;
    color: #1f396d !important;
}
.rememberme_text {
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.14 !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: #4d5f75 !important;
}
.form_title {
    width: 271px;
    height: 35px;
    object-fit: contain !important;
    font-family: Roboto !important;
    font-size: 30px !important;
    font-weight: 500 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.67 !important;
    letter-spacing: normal !important;
    text-align: left !important;
    color: #1f396d !important;
  }
  .profile_form_title {
    width: 100px;
    height: 19px;
    font-family: Roboto !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.19 !important;
    letter-spacing: normal !important;
    text-align: left !important;
    color: #1f396d !important;
  }
.rename_form_title {
  width: 150px;
  height: 19px;
  font-family: Roboto !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.19 !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: #1f396d !important;
}
.confirm_delete_form_title {
  width: 550px;
  height: 40px;
  font-family: Roboto !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.19 !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: #1f396d !important;
}
.confirm_delete_content {
  width: 550px;
  height: 40px;
  font-family: Roboto !important;
  font-size: 13.5px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.19 !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: #1f396d !important;
}
  .enterdetails_text {
    width: 400px;
    height: 40px;
    font-family: Roboto !important;
    font-size: 15px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.5 !important;
    letter-spacing: normal !important;
    text-align: left !important;
    margin-top: 1em !important;
    color: #4d5f75 !important;
  }
  .somethingwrong_text {
    width: 400px;
    height: 16px;
    font-family: Roboto !important;
    font-size: 14px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1 !important;
    letter-spacing: normal !important;
    text-align: left !important;
    color: #e53935 !important;
    margin-bottom: 1em !important;
    margin-left: 2em !important;
  }

  .Cancel {
    width: 43px;
    height: 16px;
    object-fit: contain !important;
    font-family: Roboto !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 3.57 !important;
    letter-spacing: normal !important;
    text-align: left !important;
    color: #4d5f75 !important;
  }


