.save_button {
        width: 4em;
        height: 3em;
        background: #2892d7 !important;
        color: white !important;
        border-radius: 28px !important;
        margin-bottom: 2em !important;
}
.upload_button {
  width: 72px;
  height: 32px;
  background-color: #1f396d !important;
  color: white !important;
  border-radius: 28px !important;
}
.testemail_button {
  width: 150px;
  height: 32px;
  background-color: #1f396d !important;
  color: white !important;
  border-radius: 28px !important;
}
.textBoxSettings {
  width: 264px !important;
  height: 32px !important;
  border-radius: 4px !important;
  border: solid 1px #c7cfdb !important;
  background-color: #ffffff !important;
}
.button_location {
  display: flex !important;
  justify-content: flex-end !important;
  margin-top: 1em !important;
}
.menu_text {
  font-family: Roboto !important;
  font-size: 1rem !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: #4d5f75 !important;
  padding-left: 1em !important;
}
.settingsText {
  font-family: Roboto !important;
  font-size: 0.9rem !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: #4d5f75 !important;
  margin-top: 0.3em !important;
  margin-bottom: 0.3em !important;
  margin-left: 1em !important;
}
.settingsTextBold {
  font-family: Roboto !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: #4d5f75 !important;
  margin-top: 0.3em !important;
  margin-bottom: 0.3em !important;
  margin-left: 1em !important;
}
.settingHeader {
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  font-family: Roboto !important;
  color: #1f396d !important;
  font-size: 1rem !important;
}
.spaceSettings {
  margin-top: 1em !important;
  margin-bottom: 1em !important;
}
.settings_toggle_button_group {
  border-radius: 16px !important; 
  background-color: #E9EBEE !important;
  overflow: hidden;
  height: 32px !important;
}
.settings_toggle_button {
  height: 3em !important;
  background-color: #E9EBEE !important;
  border: 0px !important;
}
.settings_toggle_button_selected {
  background-color: #2892d7  !important;
  color: white !important;
  height: 32px !important;
  border-radius: 16px !important;
  z-index: 1;
}
.somethingwrong_text {
  width: 400px;
  height: 16px;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1 !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: #e53935 !important;
}
.allok_text {
  width: 400px;
  height: 16px;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1 !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: green !important;
}

