
.report_page {
  width: 70%;
  background-color: white;
  /* border: 1px solid black; */
  margin: auto;
  text-align: center;
  margin-top: 90px;
  margin-bottom: 100px;
}

@media (max-width: 600px){
  .report_page {
    width: 100%;
  }
  
}

.footer_strip {
  height: 36px;
  width: 90%;
}


.separator {
  width: 90%;
}

header  h2 {
  margin: -2px;
}




.gif_player {
  /* border: 5px solid ; */
  /* background: white; */
  animation: slide-up 0.4s ease;
  background: black;
  display: flex;
  flex-direction: column;
}

@keyframes slide-up {
  0% {
      opacity: 0;
     
  }
  100% {
      opacity: 1;
     
  }
}
.gif_player >button.close {
  border-radius: 50%;
  width: 50px;
  margin-left: 10px;
  margin-right: 10px;
  /* -webkit-appearance: none; */
  background: white;
  border: 0px;
  font-size: 2rem;
}

.white-background {
  background: white;
}


.gif_player .button {
  border: 0;
  background: transparent;
  box-sizing: border-box;
  width: 0px;
  height: 20px;

  border-color: transparent transparent transparent white;
  transition: 100ms all ease;
  cursor: pointer;
  border-style: solid;
  border-width: 9px 0 9px 15px;
}


.gif_player  .button.pause {
    border-style: double;
    border-width: 0px 0 0px 15px;
}

.gif_player .controller {
  display: flex;
  flex-direction: row;
  background: black;
  align-items: center;
}
.gif_player input {
  width: 100%;
}

.gif_player  .button:focus {
outline: 0;
}

.gif_player canvas {
  width: 100%;
}
