@import './jobReport.css';
@import './userStyles.css';
@import './settingsStyles.css';
@import './dashboardStyles.css';
@import '../../../node_modules/react-dates/lib/css/_datepicker.css';


.ui.inverted.top.fixed.menu {
  background-image: linear-gradient(
    135deg,
    rgb(24, 42, 115) 0%,
    rgb(33, 138, 174) 69%,
    rgb(32, 167, 172) 89%
  ) !important;
}

/*home page styles*/

.masthead {
  display: flex;
  align-items: center;
  background-image: linear-gradient(
          135deg,
          rgb(24, 42, 115) 0%,
          rgb(33, 138, 174) 69%,
          rgb(32, 167, 172) 89%
  ) !important;
  height: 100vh;
}

.masthead .ui.menu .ui.button,
.ui.menu a.ui.inverted.button {
  margin-left: 0.5em;
}

.masthead h1.ui.header {
  font-size: 4em;
  font-weight: normal;
}

.masthead h2 {
  font-size: 1.7em;
  font-weight: normal;
}

/*end home page styles*/

html {
  height: 100%;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  font-size: 14px;
}
html {
  /* line-height: 1.15; */
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

*, :after, :before {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.4285em;
  height: 100%
}

.h100 {
  height: 100%
}

a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

a {
  color: #4183C4;
  text-decoration: none;
}

a:hover {
  color: #1e70bf;
  text-decoration: none;
}

.arrow_box {
	position: relative;

}


.arrow_box {
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
}

.arrow_box:after, .arrow_box:before {
	left: 100%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
  z-index:2
}

.arrow_box:after {
  top: -1px;
  border-top: 24px solid transparent;
  border-bottom: 23px solid transparent;
  border-left: 23px solid;
  border-left-color:white;
  left: 99%;
  
}


.arrow_box:before {
  top: -1px;
  left: 99%;
  border-top: 24px solid transparent;
  border-bottom: 24px solid transparent;
  border-left: 24px solid lightgray;
}

.arrow_box_failed {
  background: #077187;
  border-color: #077187;
  /* color: white !important; */
  
}
.arrow_box_passed {
  background: #8bc53f;
  border-color: #8bc53f;
  /* color: white !important; */
}

.arrow_box_passed:after {
  border-left-color: #8bc53f ;
}
.arrow_box_failed:after {
  border-left-color: #077187 ;
}





.arrow_box_passed [class*="body"],.arrow_box_failed [class*="body"] {
  color: white;
}



.arrow_box {
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
}


.toggle_button_group {
  border-radius: 28px !important; 
  background-color: #E9EBEE !important;
  overflow: hidden;
  height: 34px;
  
}


.toggle_button {
  background-color: #E9EBEE !important;
  border: 0px !important;
  width: 50%;
  text-transform: none !important;
  height: 34px !important;
}

.toggle_button_selected {
  background-color: #2892d7  !important;
  color: white !important;
  border-radius: 28px !important;
  z-index: 1;
}


.DateRangePickerInput {
  border: 0px !important;
  border-radius: 28px !important;
  overflow: hidden;
}
.DateInput_input__focused {
  border: 0px !important;
}

.CalendarDay__selected_span {
  background: #badcf3;
  color: black;
  border: 0 ;
}
 
.CalendarDay__selected {
  background: #2892d7;
  color: white;
}

.CalendarDay__default {
  border: 0;
}

.CalendarMonth_table {
  margin-top: 10px;
}

.DayPickerNavigation_button {
  border-radius: 20px;
  padding: 6px 6px;
}
 

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background: transparent !important;
}

.MuiExpansionPanelSummary-expandIcon.Mui-expanded {
  transform: rotate(90deg) !important;
}

.Mui-error {
  color: red !important
}

/* css for print preview */
@media print {
  body * {
    visibility: hidden;
  }

  .print {
    width: 100% !important;
    margin: 0 !important
  }
  .print, .print * {
    visibility: visible;
  }
  .print {
    position: absolute;
    left: 0;
    top: 0;
  }


  .p-20 {
    width: 20% !important;
  }
  .p-max-20 {
    max-width: 20% !important;
  }
  .p-max-19 {
    max-width: 19% !important;
  }

  .p-max-60 {
    max-width: 60% !important;
  }
  .p-max-40 {
    max-width: 40% !important;
  }

  .p-max-70 {
    max-width: 70% !important;
  }
  .p-max-30 {
    max-width: 30% !important;
  }
  .d-print {
    display: block !important;
  }
  .avoid-page-break {
    page-break-inside: avoid;
  }
}


.MuiSkeleton-root {
  background: rgba(0,0,0,0.2) !important;
}
.MuiDrawer-paperAnchorDockedLeft {
  border-right: 0 solid rgba(0, 0, 0, 0.12) !important;
}

.dateRangePicker .MuiInput-underline:before {
  border-bottom: none !important;
}

.dateRangePicker .MuiInput-underline:hover:before {
  border-bottom: none !important;
}

.scrollbox {
  overflow: auto;
  visibility: hidden;
}

.scrollbox-content,
.scrollbox:hover,
.scrollbox:focus {
  visibility: visible;
}

.scrollbox_delayed {
  transition: visibility 0.2s;
}

.scrollbox_delayed:hover {
  transition: visibility 0s 0.2s;
}

.disabled-background {
  background-color: lightGray !important;
}
.jobFilter {
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.14 !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: #1f396d !important;
}

.blur {
  position: relative;
}

.blur:after{
  content: '';
  width: 100%;
  height: 100%;
 
  
  position: absolute;

  right: 0;

  bottom: 0;
  box-shadow: inset 0 0 0 200px rgba(255,255,255,0.05);
  background-color:rgba(255,255,255,0.65);
  filter: blur(100px);
 }

 .MuiTooltip-tooltip {
   font-size: 1rem !important;
 }

 .hide-scrollbar::-webkit-scrollbar {
    display: none;
 }

 .cursor-pointer {
   cursor: pointer;
 }